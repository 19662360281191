import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as styles from "./ViniProductPage.module.css";
import "../../pages/Accordance.css";
import Header from "../../Components/Header/Header";
import axios from "axios";
import Footer from "../../Components/Footer/Footer";
// import wineStore from "../../assets/images/wineStore.png";
import preArrow from "../../assets/images/preview.png";
import nextArrow from "../../assets/images/next.png";
// import Slider from "react-slick";
import Modal from "react-modal";
import { graphql } from "gatsby";
import classNames from "classnames";
// import Calendar from "rc-year-calendar";
import "../../pages/Calendar.css";
import AliceCarousel from "react-alice-carousel";
import "./aliceCarousel.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { languageContext } from "../../Components/Header/Header";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
// import { getUrl, getYear, download } from "../../util/viniProductPage.helper";

const isMobile = typeof window !== "undefined" && window.innerWidth < 767;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: isMobile ? "370px" : "50%",
    width: isMobile ? "90%" : "50%",
    transform: "translate(-50%, -50%)",
    padding: isMobile ? "48px" : "48px 64px 66px",
    maxHeight: "400px",
    maxWidth: isMobile ? "341px" : "373px",
  },
};

const ViniProductPage = ({ data }) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data?.strapiProduct?.localizations || {};
  const {
    ProductImage: localProductImage,
    ProductLogoImg: localProductLogoImg,
    Image: localImage,
    SeperatorImage: localSeperatorImage,
  } = (Array.isArray(localizations?.data) && localizations?.data.length &&  localizations?.data[0]?.attributes) || {};

  const mapContent =
    language === "it"
      ? data?.strapiProduct?.localizations?.data[0]?.attributes?.Content
      : data?.strapiProduct?.Content || [];

  const {
    ProductName,
    ProductImage,
    ProductLogoImg,
    Image,
    SeperatorImage,
    ProductDescription,
    BackToVini,
    SelectYear,
    DownloadText,
    Schedateenicatext,
  } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.strapiProduct || {};



  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const { DataSheet: pdfDataSheet } = language === "it"
  ? localizations?.data[0]?.attributes: data?.strapiProduct || {};



  //Pdf  function
  const getUrl = (array) => {
    return (
      Array.isArray(array) && array.map((item) => language === "it" ? item?.uploadPDF?.data?.attributes?.url : item?.uploadPDF?.url)
    );
  };
  
  const getYear = (array) => {
    return Array.isArray(array) && array.map((item) => item?.Year);
  };
  
  function download(url) {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const urlObject =
        typeof window !== "undefined" &&
        window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", url.replace("https://popseekl.s3.eu-west-3.amazonaws.com/", "") +".pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
  
  const [activeIndex, setActiveIndex] = useState(0);
  const urls = getUrl(pdfDataSheet);
  const years = getYear(pdfDataSheet);

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);

  //Modal
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const titleColorClassName =
    ProductName === "Malvasia"
      ? styles.red
      : ProductName === "Sauvignon"
      ? styles.green
      : ProductName === "Ribolla Gialla"
      ? styles.yellow
      : ProductName === "Friulano"
      ? styles.brown
      : ProductName === "Schioppettino"
      ? styles.pink
      : ProductName === "Chardonnay"
      ? styles.blue
      : null;

  const LinkColorClassName =
    ProductName === "Malvasia"
      ? styles.redLink
      : ProductName === "Sauvignon"
      ? styles.greenLink
      : ProductName === "Ribolla Gialla"
      ? styles.yellowLink
      : ProductName === "Friulano"
      ? styles.brownLink
      : ProductName === "Schioppettino"
      ? styles.pinkLink
      : ProductName === "Chardonnay"
      ? styles.blueLink
      : null;

  const upperImageClassName =
    ProductName === "Malvasia"
      ? styles.upperImageRed
      : ProductName === "Sauvignon"
      ? styles.upperImageGreeen
      : ProductName === "Ribolla Gialla"
      ? styles.upperImageYellow
      : ProductName === "Friulano"
      ? styles.upperImageBrown
      : ProductName === "Schioppettino"
      ? styles.upperImagePink
      : ProductName === "Chardonnay"
      ? styles.upperImageBlue
      : null;

  const borderColorClassName =
    ProductName === "Malvasia"
      ? styles.red_1
      : ProductName === "Sauvignon"
      ? styles.greeen_1
      : ProductName === "Ribolla Gialla"
      ? styles.yellow_1
      : ProductName === "Friulano"
      ? styles.brown_1
      : ProductName === "Schioppettino"
      ? styles.pink_1
      : ProductName === "Chardonnay"
      ? styles.blue_1
      : null;

  const accordanceColorClassName =
    ProductName === "Malvasia"
      ? styles.red_2
      : ProductName === "Sauvignon"
      ? styles.greeen_2
      : ProductName === "Ribolla Gialla"
      ? styles.yellow_2
      : ProductName === "Friulano"
      ? styles.brown_2
      : ProductName === "Schioppettino"
      ? styles.pink_2
      : ProductName === "Chardonnay"
      ? styles.blue_2
      : null;

  const nameFontClassName =
    ProductName === "Malvasia"
      ? styles.redName
      : ProductName === "Sauvignon"
      ? styles.greeenName
      : ProductName === "Ribolla Gialla"
      ? styles.yellowName
      : ProductName === "Friulano"
      ? styles.brownName
      : ProductName === "Schioppettino"
      ? styles.pinkName
      : ProductName === "Chardonnay"
      ? styles.blueName
      : null;

  return (
   <>
    <div className={classNames("pageWrapper")}>
      <Header />
    <div className={styles.viniProductContiner}>
    <div className={styles.viniBoxWraper}>
        <span className={styles.productImageCenter}>
          <img
            src={
              language === "it"
                ? localProductImage?.data?.attributes?.url
                : ProductImage?.url
            }
            alt="productImage"
          />
        </span>
        <div className={styles.productName}>
          <h1 className={classNames(nameFontClassName)}>{ProductName}</h1>
        </div>
        <div className={styles.productDataDescription}>
          <div className={classNames(styles.descriptiontext)}>{ProductDescription}</div>
          <div>
            <span
              onClick={openModal}
              className={classNames(LinkColorClassName, styles.dataSheet)}
            >
              <h4 className={classNames(titleColorClassName)}>
                {Schedateenicatext}
              </h4>
              <span>
                <svg
                  className={classNames(titleColorClassName)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <g transform="rotate(90 12 12)">
                    <path
                      fill="currentColor"
                      d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                    />
                  </g>
                </svg>
              </span>
              {/* <img src={Schedatecnica?.url}></img> */}
            </span>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className={`${styles.ModalSection} ViniProduct`}>
                <h4>{SelectYear}</h4>
                <div className={styles.yearMain}>
                  <AliceCarousel
                    key="carousel"
                    mouseTracking
                    disableDotsControls
                    disableButtonsControls
                    items={years}
                    activeIndex={activeIndex}
                  ></AliceCarousel>
                  <div className={"b-refs-buttons"}>
                    {activeIndex !== 0 ? (
                      <button className={styles.preArrow} onClick={slidePrev}>
                        <img src={preArrow} alt="arrow" />
                      </button>
                    ) : null}
                    {years.length - 1 !== activeIndex ? (
                      <button className={styles.nextArrow} onClick={slideNext}>
                        <img src={nextArrow} alt="arrow" />
                      </button>
                    ) : null}
                  </div>
                </div>
                <button
                  className={styles.ModalButton}
                  onClick={() => download(urls[activeIndex])}
                >
                  {DownloadText}
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
      <div className={styles.productCoverBox}>
        <img
          src={
            language === "it"
              ? localSeperatorImage?.Image?.data?.attributes?.url
              : SeperatorImage?.Image?.url
          }
          alt="productImage"
        />
      </div>
      <div className={styles.productMainWrap}>
        <div className={styles.notesHeading}>
          <h1>{ProductName}</h1>
        </div>
        <div className={`${styles.productAccordanceBox} productAccordanceBox`}>
          <Accordion
            open={open}
            toggle={toggle}
            // className={styles.accordionBox}
          >
            { mapContent?.map((item, id) => (
              <AccordionItem className={classNames(accordanceColorClassName)}>
                <AccordionHeader
                  targetId={id}
                  className={classNames(styles.accordanceHeader)}
                >
                  {item?.Title}
                </AccordionHeader>
                <AccordionBody
                  accordionId={id}
                  className={styles.accordanceBody}
                >
                  {item?.Description}
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
      <div className={styles.wineImage}>
        <img
          className={styles.winePic}
          src={
            language === "it" ? localImage?.data?.attributes?.url : Image.url
          }
          alt="it"
        />
        <img
          className={classNames(upperImageClassName)}
          src={
            language === "it"
              ? localProductLogoImg?.data?.attributes?.url
              : ProductLogoImg.url
          }
          alt="it"
        />
      </div>
      <div className={styles.bottomDetail}>
        <a href={BackToVini?.Link?.href}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <p className={classNames(borderColorClassName, 'animate__animated animate__fadeInUp')}>
            {BackToVini?.Link?.label}
          </p>
          </AnimationOnScroll>
        </a>
      </div>
    </div>
    <Footer />
   </>
  );
};

export default ViniProductPage;

export const query = graphql`

  query MyQueryAndMyQuery($slug: String) {
    strapiProduct(slug: { eq: $slug }) {
      Content {
        Title
        Description
      }
      Image {
        url
      }
      ProductDescription
      slug
      SelectYear
      DownloadText
      Schedateenicatext
      ProductName
      ProductImage {
        url
      }

      ProductLogoImg {
        url
      }
      SchedatecnicaLink {
        href
      }
      SeperatorImage {
        Image {
          url
        }
      }
      BackToVini {
        Link {
          href
          label
        }
      }
      DataSheet {
        uploadPDF {
          url
        }
        Year
      }
      localizations {
        data {
          attributes {
            BackToVini {
              Link {
                href
                label
              }
            }
            Content {
              Description
              Title
            }
            Image {
              data {
                attributes {
                  url
                }
              }
            }
            ProductDescription
            SelectYear
            Schedateenicatext
            DownloadText
            ProductImage {
              data {
                attributes {
                  url
                }
              }
            }
            ProductLogoImg {
              data {
                attributes {
                  url
                }
              }
            }
            ProductName
            SchedatecnicaLink {
              alt
              href
            }
            SeperatorImage {
              Image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ThumbnailIcon {
              Image {
                data {
                  attributes {
                    url
                  }
                }
              }
              Link {
                href
              }
            }
            ThumbnailImage {
              data {
                attributes {
                  url
                }
              }
            }
            ThumbnailName
            slug
            DataSheet {
              uploadPDF {
                data {
                  attributes {
                    url
                  }
                }
              }
              Year
            }
          }
        }
      }
    }
  }

  
  
  
`;
