// extracted by mini-css-extract-plugin
export var ModalButton = "ViniProductPage-module--ModalButton--dfdff";
export var ModalMain = "ViniProductPage-module--ModalMain--447ac";
export var ModalSection = "ViniProductPage-module--ModalSection--5ff85";
export var ReactModal__Content = "ViniProductPage-module--ReactModal__Content--6b5bb";
export var accordanceBody = "ViniProductPage-module--accordanceBody--53347";
export var accordanceHeader = "ViniProductPage-module--accordanceHeader--d0469";
export var blue = "ViniProductPage-module--blue--1e073";
export var blueLink = "ViniProductPage-module--blueLink--79218";
export var blueName = "ViniProductPage-module--blueName--f8a74";
export var blue_1 = "ViniProductPage-module--blue_1--cfcc8";
export var blue_2 = "ViniProductPage-module--blue_2--2f50f";
export var bottomDetail = "ViniProductPage-module--bottomDetail--13a9d";
export var brown = "ViniProductPage-module--brown--bc1fa";
export var brownLink = "ViniProductPage-module--brownLink--e564a";
export var brownName = "ViniProductPage-module--brownName--dea3e";
export var brown_1 = "ViniProductPage-module--brown_1--35d39";
export var brown_2 = "ViniProductPage-module--brown_2--b8d01";
export var collapsed = "ViniProductPage-module--collapsed--f12eb";
export var dataSheet = "ViniProductPage-module--dataSheet--d926d";
export var descriptiontext = "ViniProductPage-module--descriptiontext--1b722";
export var greeen = "ViniProductPage-module--greeen--3f3eb";
export var greeenLink = "ViniProductPage-module--greeenLink--4a521";
export var greeenName = "ViniProductPage-module--greeenName--001a0";
export var greeen_1 = "ViniProductPage-module--greeen_1--aff71";
export var greeen_2 = "ViniProductPage-module--greeen_2--7faa5";
export var nextArrow = "ViniProductPage-module--nextArrow--50c3c";
export var notesHeading = "ViniProductPage-module--notesHeading--7da67";
export var pink = "ViniProductPage-module--pink--e51e1";
export var pinkLink = "ViniProductPage-module--pinkLink--36111";
export var pinkName = "ViniProductPage-module--pinkName--51b9c";
export var pink_1 = "ViniProductPage-module--pink_1--777e7";
export var pink_2 = "ViniProductPage-module--pink_2--e2993";
export var preArrow = "ViniProductPage-module--preArrow--fab91";
export var productAccordanceBox = "ViniProductPage-module--productAccordanceBox--5db11";
export var productCoverBox = "ViniProductPage-module--productCoverBox--8b09d";
export var productDataDescription = "ViniProductPage-module--productDataDescription--b8ff8";
export var productImageCenter = "ViniProductPage-module--productImageCenter--b4b98";
export var productMainWrap = "ViniProductPage-module--productMainWrap--ee1ba";
export var productName = "ViniProductPage-module--productName--a1323";
export var productPage = "ViniProductPage-module--productPage--ab1ee";
export var red = "ViniProductPage-module--red--a67e6";
export var redLink = "ViniProductPage-module--redLink--2d997";
export var redName = "ViniProductPage-module--redName--a0e7a";
export var red_1 = "ViniProductPage-module--red_1--972db";
export var red_2 = "ViniProductPage-module--red_2--d02f0";
export var upperImage = "ViniProductPage-module--upperImage--802b1";
export var upperImageBlue = "ViniProductPage-module--upperImageBlue--efe47";
export var upperImageBrown = "ViniProductPage-module--upperImageBrown--83520";
export var upperImageGreeen = "ViniProductPage-module--upperImageGreeen--6ebae";
export var upperImagePink = "ViniProductPage-module--upperImagePink--5e6a2";
export var upperImageRed = "ViniProductPage-module--upperImageRed--41f9d";
export var upperImageYellow = "ViniProductPage-module--upperImageYellow--8c9a1";
export var viniBoxWraper = "ViniProductPage-module--viniBoxWraper--68014";
export var wineImage = "ViniProductPage-module--wineImage--99074";
export var winePic = "ViniProductPage-module--winePic--536de";
export var yearMain = "ViniProductPage-module--yearMain--07727";
export var yellow = "ViniProductPage-module--yellow--32beb";
export var yellowLink = "ViniProductPage-module--yellowLink--c0e8e";
export var yellowName = "ViniProductPage-module--yellowName--c24f1";
export var yellow_1 = "ViniProductPage-module--yellow_1--42044";
export var yellow_2 = "ViniProductPage-module--yellow_2--349ea";